import React from "react";
import Page from "../components/Page";
import { graphql, Link } from "gatsby";
import BlogCard from "../components/Blog/BlogCard";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Index({ data }) {
  const { posts } = data.blog;

  const { featuredPosts } = data.featured;


  return (
    <>
      <Page
        title={"Fiskesajten - En fiskeblogg för nybörjare"}
        description="Fiskesajten är en sajt om fisktekniker, fisketips och fiskeutrustning. Här hittar du allt du behöver för att lyckas med ditt fiske."
      >
        <section className="bg-white ">
          <div className="grid max-w-screen-xl px-4 sm:px-8 py-12 mx-auto lg:gap-8 xl:gap-0 lg:py-20 lg:grid-cols-12">
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="max-w-2xl mb-4 text-4xl sm:text-5xl font-bold tracking-tight leading-none md:text-6xl xl:text-6xl ">
                Från första kastet till minnesvärda fångster
              </h1>
              <p className="max-w-2xl mb-6 font-light text-gray-600 lg:mb-8 text-lg lg:text-xl ">
                Dyk ner i en värld av fiske. Här hittar du allt från tips på fiskeutrustning till olika fiskemetoder, och allt däremellan.
              </p>

              <Link
                to="/blogg/"
                className="inline-flex px-5 py-3 text-base font-medium text-center text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100  "
              >
                Läs fiskebloggen
              </Link>
            </div>
            <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
              <GatsbyImage
                image={data.image.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
        </section>


        <section className="bg-white ">
          <div className="gap-8 items-center py-12 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-20 lg:px-6">
            <GatsbyImage
              image={data.image2.childImageSharp.gatsbyImageData}
              className="w-full rounded-lg"
            />
            <div className="mt-4 md:mt-0">
              <h2 className="mb-4 text-3xl sm:text-4xl tracking-tight font-bold text-gray-900 ">
                Guide och tips för din fiskeupplevelse
              </h2>
              <p className="mb-4 font-light text-gray-600 text-lg">
                Fiske är både konst och vetenskap. Oavsett om du är nybörjare eller veteran erbjuder vi insikter från utrustningsval till hållbart fiske. Varje kast är en chans att lära och bidra till ett bättre fiskesamhälle.
              </p>
              <p className="mb-4 font-light text-gray-600 text-lg">
                Utforska våra guider och förbättra din fiskekunskap medan du värnar om naturen.
              </p>
            </div>
          </div>
        </section>


        <section>
          <div className="py-12 px-4 mx-auto max-w-screen-xl sm:py-20 lg:px-6">
            <h2 className="mb-4 text-3xl sm:text-4xl tracking-tight font-bold text-gray-900 ">
              Populära artiklar
            </h2>
            <div className="blog-grid">
              {featuredPosts &&
                featuredPosts.map((post) => {
                  const href = "/" + post.frontmatter.category + post.fields.slug;

                  return (
                    <BlogCard
                      key={post.id}
                      title={post.frontmatter.title}
                      description={post.frontmatter.description}
                      link={href}
                      image={post.frontmatter?.coverImage}
                    />
                  );
                })}
            </div>
          </div>
        </section>

        <section>
          <div className="py-12 px-4 mx-auto max-w-screen-xl sm:py-20 lg:px-6">
            <div className="max-w-screen-md mb-8 lg:mb-16">
              <h2 className="mb-4 text-3xl sm:text-4xl tracking-tight font-bold text-gray-900">
                Din fiskekompanjon på nätet
              </h2>
              <p className="text-gray-600 text-lg sm:text-xl ">
                Målet med Fiskesajten är att skapa ett hem för fiskeentusiaster. Vi vill guida, inspirera och dela med oss av erfarenhet för att hjälpa alla att bli bättre fiskare.
              </p>
            </div>
            <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
              <div>
                <h3 className="mb-2 text-xl font-bold ">🎣 Fisketekniker</h3>
                <p className="text-gray-600 ">
                  Genom år av erfarenhet har vi finslipat vår teknik - från spinnfiske till flugfiske. Utforska med oss och lär dig de små nyanserna som gör stora skillnader.</p>
              </div>
              <div>

                <h3 className="mb-2 text-xl font-bold ">🐟 Fisketips</h3>
                <p className="text-gray-600 ">
                  Från lugna dagar vid sjön till de mest utmanande fiskeäventyren, vi delar med oss av de råd som hjälpt oss mest genom åren.
                </p>
              </div>
              <div>

                <h3 className="mb-2 text-xl font-bold ">🎣 Utvald fiskeutrustning</h3>
                <p className="text-gray-600 ">
                  För oss handlar det om att använda rätt verktyg för jobbet. Vi visar dig vad vi har i vår fiskeväska och varför.

                </p>
              </div>
              <div>

                <h3 className="mb-2 text-xl font-bold ">🐟 Sveriges fiskeplatser</h3>
                <p className="text-gray-600 ">
                  Vi har fiskat på många platser - från norr till söder. Upptäck några av våra favoritplatser och planera din nästa fiskeresa.
                </p>
              </div>
              <div>

                <h3 className="mb-2 text-xl font-bold ">🎣 Fiskekläder</h3>
                <p className="text-gray-600 ">
                  Om det är en sak vi har lärt oss så är det att vara förberedd. Vi delar våra favoritkläder som håller oss torra, varma och redo för nästa kast.
                </p>
              </div>
              <div>

                <h3 className="mb-2 text-xl font-bold ">🐟 Fiskevård för framtiden</h3>
                <p className="text-gray-600 ">
                  Älskar du fiske som vi? Lär dig hur vi kan bidra till hållbara fiskebestånd och säkerställa glädjen för kommande generationer.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
          <div className="bg-gray-50 p-8 rounded-lg shadow-md mx-auto my-10 border border-gray-200 grid grid-cols-1 md:grid-cols-2 gap-4">
            <GatsbyImage
              alt="Lucas Rosvall"
              image={data.me.childImageSharp.gatsbyImageData}
              className="max-w-xs sm:max-w-sm rounded-full mx-auto "
            />
            <div>
            <h2 className="text-2xl mt-6 mb-3 tracking-tight font-bold text-gray-900">
              Min återupptäckta passion för fiske 🎣
            </h2>
            <p className="text-gray-800 text-lg mb-4">
              Som barn spenderade jag timmar vid vattnet. Men när universitetet och vuxenlivet kallade tog jag tyvärr en paus från fisket, men passionen försvann aldrig helt. 
            </p>
            <p className="text-gray-800 text-lg mb-4"> 
              Nu efter en lång tid borta från spöt har jag återvänt, och kanske mer engagerad än någonsin? Det är också därför jag startade <b>Fiskesajten</b> för att dela med mig av mitt stora intresse.
            </p>
            <p className="text-gray-800 text-lg mb-4">
              Jag fiskar främst efter <b>abborre och gädda</b> i Göteborgstrakten, samt en del havsöring på västkusten. Och när det kommer till fisketekniker, är det främst jiggfiske och olika finesstekinker som gäller!
            </p>
            </div>
           
          </div>
          </div>
        </section>

        <section>
          <div className="py-12 px-4 mx-auto max-w-screen-xl sm:py-20 lg:px-6">
            <h2 className="mb-4 text-3xl sm:text-4xl tracking-tight font-bold text-gray-900 ">
              Senaste artiklar
            </h2>
            <div className="blog-grid">
              {posts &&
                posts.map((post) => {
                  const href = "/" + post.frontmatter.category + post.fields.slug;

                  return (
                    <BlogCard
                      key={post.id}
                      title={post.frontmatter.title}
                      description={post.frontmatter.description}
                      link={href}
                      image={post.frontmatter?.coverImage}
                    />
                  );
                })}
            </div>
          </div>
        </section>

      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query home {
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(/blog/)/" } }
      limit: 3
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          category
          description
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        fields {
          slug
        }
        id
        excerpt(pruneLength: 120)
      }
    }
    featured: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(/blog/)/" },
                frontmatter: {featured: {eq: true}}}
      limit: 3
    ) {
      featuredPosts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          category
          description
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        fields {
          slug
        }
        id
        excerpt(pruneLength: 120)
      }
    }
    image: file(relativePath: { eq: "pike-home.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    image2: file(relativePath: { eq: "abborre-drop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    me: file(relativePath: { eq: "lucas-fiskar.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
